<script>
import ChallengeBannerView from "./ChallengeBannerView.vue";
import { GetChallengeForBannerAuthenticated, GetChallengeForBannerUnauthenticated } from "../challengeOperations.gql";
export default {
  name: "ChallengeBannerPipe",
  components: {
    ChallengeBannerView
  },
  props: {
    challengeId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingCount: 0
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    }
  },
  apollo: {
    resultChallenge: {
      query() {
        return this.$auth.isAuthenticated ? GetChallengeForBannerAuthenticated : GetChallengeForBannerUnauthenticated;
      },
      variables() {
        const vars = {
          id: this.challengeId
        };
        if (this.$auth.isAuthenticated) {
          vars.userId = this.$auth.user.id;
        }
        return vars;
      },
      skip() {
        return !this.challengeId;
      },
      loadingKey: "loadingCount"
    }
  },

};
</script>

<template>
  <div class="challenge-banner-container">
    <ChallengeBannerView :challenge="resultChallenge" :is-loading="isLoading">
      <template #button>
        <slot name="button"></slot>
      </template>
    </ChallengeBannerView>
  </div>
</template>

<style lang="scss" scoped>
.challenge-banner-container {
  width: 100%;
}
</style>

<script>
import { SuawIcon } from "@/components";

export default {
  name: "ChallengeProgressBar",
  components: { SuawIcon },
  props: {
    prompts: {
      type: Array,
      required: true
    }
  }
};
</script>

<template>
  <div class="suaw-challenge-progress-bar">
    <div
      v-for="(prompt, index) in prompts"
      :key="index"
      class="progress-section"
      :class="{
        'completed': prompt.isSubmitted,
        'unlocked': !prompt.isSubmitted && prompt.isUnlocked,
        'locked': !prompt.isSubmitted && !prompt.isUnlocked
      }"
    >
      <div class="content">
        <SuawIcon v-if="prompt.isSubmitted" icon="IconCheckboxChecked" color="gray" :pixel-size="24" />
        <SuawIcon v-else-if="!prompt.isUnlocked" icon="IconLock" color="dark-gray" :pixel-size="26" />
        <SuawIcon v-else icon="IconCheckbox" color="gray" :pixel-size="24" />
        <span class="day-text">
          <span class="day-label">DAY </span>
          <span class="day-number">{{ index + 1 }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-challenge-progress-bar {
  width: 100%;
  height: 32px;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid var(--sem-color-neutral-regular);
}

.progress-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-right: 1px solid var(--sem-color-neutral-regular);

  &:last-child {
    border-right: none;
  }

  &.completed {
    background: var(--sem-color-success-light);
  }

  &.unlocked {
    background: var(--sem-color-background-lightest);
  }

  &.locked {
    background: var(--sem-color-background-regular);
  }

  .content {
    display: flex;
    align-items: center;
    gap: 8px;
    @media (max-width: 1000px) {
      gap: 2px;
    }
  }

  .day-text {
    color: var(--sem-color-neutral-dark);

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 1000px) {
      font-size: 14px;
    }
    @media (max-width: 850px) {
      .day-label {
        display: none;
      }
    }
  }
}
</style>

<script>
import { format } from "date-fns";
import { SuawParagraph, SuawEmptyState } from "@/components";
import ChallengeProgressBar from "./ChallengeProgressBar.vue";
export default {
  name: "ChallengeBannerView",
  components: {
    SuawParagraph,
    ChallengeProgressBar,
    SuawEmptyState
  },
  props: {
    challenge: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    thumbnail() {
      if (this.isLoading) return "";
      return this.challenge.image_url;
    },
    title() {
      if (this.isLoading) return "";
      return this.challenge.title;
    },
    description() {
      if (this.isLoading) return "";
      return this.challenge.body_json;
    },
    challengeStatus() {
      const startDate = new Date(this.challenge.available_at);
      const currentDate = new Date();
      const endDate = new Date(this.challenge.available_until);
      const msPerDay = 1000 * 60 * 60 * 24;
      if (currentDate < startDate) {
        const daysUntilStart = Math.ceil((startDate - currentDate) / msPerDay);
        return {
          hasStarted: false,
          daysRemaining: daysUntilStart
        };
      } else if (currentDate <= endDate) {
        const daysUntilEnd = Math.ceil((endDate - currentDate) / msPerDay);
        return {
          hasStarted: true,
          daysRemaining: daysUntilEnd
        };
      } else {
        return {
          hasStarted: true,
          daysRemaining: 0
        };
      }
    },
    hasEnded() {
      return new Date() > new Date(this.challenge.available_until);
    },
    tagText() {
      if (!this.challengeStatus.hasStarted) {
        return `Starts in ${this.challengeStatus.daysRemaining} ${this.challengeStatus.daysRemaining === 1 ? "Day" : "Days"}`;
      } else if (this.challengeStatus.daysRemaining > 0) {
        return `Ends in ${this.challengeStatus.daysRemaining} ${this.challengeStatus.daysRemaining === 1 ? "Day" : "Days"}`;
      } else {
        return "Challenge Ended";
      }
    },
    challengeDate() {
      if (this.isLoading) return "Challenge";
      return `${format(new Date(this.challenge.available_at), "MMMM yyyy")} Challenge`;
    },
    writerCount() {
      if (this.isLoading) return 0;
      return this.challenge.challenge_users_aggregate.aggregate.count;
    },
    writerText() {
      if (this.isLoading) return "0 Writers";
      return `${this.writerCount} ${this.writerCount === 1 ? "Writer" : "Writers"}`;
    },
    prompts() {
      if (this.isLoading) return [];
      const promptsById = this.challenge.prompts.reduce((acc, prompt) => {
        acc[prompt.id] = prompt;
        return acc;
      }, {});

      return (this.challenge.prompt_sort || []).map(promptId => {
        const prompt = promptsById[promptId];
        return {
          isSubmitted: prompt.prompt_users?.[0]?.submitted_at != null,
          isUnlocked: !prompt.is_locked
        };
      });
    },
    hasAccepted() {
      if (this.isLoading) return false;
      return this.challenge.challenge_users?.length > 0;
    }
  }
};
</script>

<template>
  <div v-if="!isLoading" class="suaw-challenge-banner">
    <div class="suaw-challenge-banner__tag">{{ tagText }}</div>
    <SuawThumbnail size="fit" :content="thumbnail" image-alt="Challenge Thumbnail" class="suaw-challenge-banner__image" />
    <div class="suaw-challenge-banner__content">
      <div class="suaw-challenge-banner__header">
        <SuawParagraph class="suaw-challenge-banner__challenge-date" weight="normal" size="small" :text="challengeDate" />
        <div class="suaw-challenge-banner__writers-group">
          <SuawIcon icon="IconUser" color="carbon" size="medium" />
          <SuawParagraph class="suaw-challenge-banner__writers" weight="normal" size="small" :text="writerText" />
        </div>
      </div>
      <ChallengeProgressBar :prompts="prompts" />
      <SuawHeading class="suaw-challenge-banner__title" level="2" :content="title" />
      <SuawParagraph class="suaw-challenge-banner__description" :text="description" />
      <div class="suaw-challenge-banner__button-container">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
  <div v-else>
    <!-- needs skeleton -->
    <SuawEmptyState message="Loading Challenge Banner..." />
  </div>
</template>

<style lang="scss" scoped>
.suaw-challenge-banner {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }

  &__tag {
    position: absolute;
    top: 8px;
    left: 8px;
    background: #ffd700;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 500;
    z-index: 1;
  }

  &__image {
    max-height: 410px;
    width: 100%;
    border-radius: 4px;
  }

  &__content {
    display: grid;
    grid-template-rows: auto auto auto 1fr auto;
    gap: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__challenge-date {
    color: var(--sem-color-neutral-medium);
    font-weight: 500;
  }

  &__writers-group {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__writers {
    // color: var(--sem-color-info-medium);
    color: var(--sem-color-text-medium);
    font-weight: 500;
    font-size: 16px;
  }

  &__title {
    @media (max-width: 1000px) {
      font-size: 28px;
    }
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 8;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    max-height: 100%;
    align-self: start;
    @media (max-width: 1000px) {
      line-clamp: 7;
      -webkit-line-clamp: 7;
    }
    @media (max-width: 850px) {
      line-clamp: 6;
      -webkit-line-clamp: 6;
    }
    @media (max-width: 650px) {
      line-clamp: none;
      -webkit-line-clamp: none;
      max-height: none;
    }
  }

  &__button {
    justify-self: start;
    margin-bottom: 16px;
    @media (max-width: 650px) {
      width: 100%;
    }
  }

  &__no-button-text {
    margin-bottom: 16px;
  }
}
</style>
